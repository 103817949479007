<template>
    <!-- Accomplishments component -->
    <v-container fluid class="mt-16 px-0" id="accomplishments">
    <div class="componentdivider"></div>      
    <v-row>
        <v-col cols="8">
        <h1 class="headerfont text-wrap">
            Accomplishments
        </h1>
        </v-col>
    </v-row>
    <!-- row1 -->
    <v-row>
        <!-- card1 -->
        <v-col cols="12" md="12" lg="6">
          <v-card shaped class="experiencecard" href="https://www.imda.gov.sg/How-We-Can-Help/Singapore-Digital-Scholarship" target="__blank">
            <v-row>
              <v-col cols="12" xs="12" sm="7" md="9">
                <v-row>
                  <v-col cols="12">
                    <v-card-title style="font-size: 1.38rem;" class="text-wrap">
                      Singapore Digital (SG:D) Scholarship Recipient
                    </v-card-title>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12">
                    <v-card-subtitle style="font-size: 1.1rem;" class="text-wrap">
                        Issued by Infocomm Media Development Authority (IMDA) · Aug 2022
                    </v-card-subtitle>
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="12" xs="12" sm="5" md="3" style="display: flex; align-items: center;" class="text-wrap">
                <v-img src="@/assets/imdalogo.png" class="accomplishmentimage"></v-img>
              </v-col>
            </v-row>
            <v-row class="mt-3">
              <v-col cols="12" class="pt-0">
                <v-card-text style="font-size: 1.1rem;" class="text-wrap">
                    The Singapore Digital (SG:D) Scholarship (Undergraduate) is an industry scholarship 
                    that empowers students interested in pursuing tech or media-related studies at 
                    the undergraduate level with a wealth of opportunities.
                    It is made up of a community of passionate individuals who want to make a 
                    meaningful impact on society through harnessing the power of Tech and Media.
                </v-card-text>
              </v-col>
            </v-row>
          </v-card>
        </v-col>

        <!-- card2 -->
        <v-col cols="12" md="12" lg="6">
          <v-card shaped class="experiencecard" href="https://scis.smu.edu.sg/undergraduate/beyond/students" target="__blank">
            <v-row>
              <v-col cols="12" xs="12" sm="7" md="9">
                <v-row>
                  <v-col cols="12">
                    <v-card-title style="font-size: 1.38rem;" class="text-wrap">
                      SMU SCIS VMware Tanzu Award 
                    </v-card-title>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12">
                    <v-card-subtitle style="font-size: 1.1rem;" class="text-wrap">
                      Issued by SMU School of Computing & Information Systems · Apr 2023
                    </v-card-subtitle>
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="12" xs="12" sm="5" md="3" style="display: flex; align-items: center;" class="text-wrap">
                <v-img src="@/assets/smuscislogo.png" class="accomplishmentimage"></v-img>
              </v-col>
            </v-row>
            <v-row class="mt-3">
              <v-col cols="12" class="pt-0">
                <v-card-text style="font-size: 1.1rem;" class="text-wrap">
                  The <u>Top 3 Teams</u> in the cohort taking the Enterprise Solution Development Module are shortlisted by Singapore
                  Management University to present their solution ('GoBook' listed above) 
                  to the VMware panel. <br><br>
                  The teams are graded based on the technical competency of the solution built, 
                  cloud-native best practices, overall quality of the project and presentation, and best 
                  practices employed in the solution that improves the environmental sustainability footprint.
                </v-card-text>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
      </v-row>

      <!-- row2 -->
      <v-row>
        <!-- card3 -->
        <v-col cols="12" md="12" lg="6">
          <v-card shaped class="experiencecard" href="https://scis.smu.edu.sg/programmes/undergraduate/beyond/ace" target="__blank">
            <v-row>
              <v-col cols="12" xs="12" sm="7" md="9">
                <v-row>
                  <v-col cols="12">
                    <v-card-title style="font-size: 1.35rem;" class="text-wrap">
                      SMU SCIS ACE Program Professional Development Director
                    </v-card-title>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12">
                    <v-card-subtitle style="font-size: 1.1rem;" class="text-wrap">
                        Issued by SMU School of Computing & Information Systems Ace Program · Apr 2022 - Present
                    </v-card-subtitle>
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="12" xs="12" sm="5" md="3" style="display: flex; align-items: center;">
                <v-img src="@/assets/smuscislogo.png" class="accomplishmentimage"></v-img>
              </v-col>
            </v-row>
            <v-row class="mt-3">
              <v-col cols="12" class="pt-0">
                <v-card-text style="font-size: 1.1rem;" class="text-wrap">
                  The ACE Program aims to develop students as leaders who care for the SCIS community by providing them 
                  with unique outside-the-classroom learning experiences. The students serve as SCIS ambassadors who enjoy 
                  their SMU experiences and are ever-ready to share these experiences with others around them.               
                </v-card-text>
              </v-col>
            </v-row>
          </v-card>
        </v-col>

        <!-- card4 -->  
        <v-col cols="12" md="12" lg="6">
          <v-card shaped class="experiencecard" href="https://admissions.smu.edu.sg/financial-matters/financial-aid/smu-school-computing-and-information-systems-scis-achievements" target="__blank">
            <v-row>
              <v-col cols="12" xs="12" sm="7" md="9">
                <v-row>
                  <v-col cols="12">
                    <v-card-title style="font-size: 1.38rem;" class="text-wrap">
                      SMU SCIS Achievements Scholarship Recipient
                    </v-card-title>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12">
                    <v-card-subtitle style="font-size: 1.1rem;" class="text-wrap">
                        Issued by SMU School of Computing & Information Systems · May 2021
                    </v-card-subtitle>
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="12" xs="12" sm="5" md="3" style="display: flex; align-items: center;">
                <v-img src="@/assets/smuscislogo.png" class="accomplishmentimage"></v-img>
              </v-col>
            </v-row>
            <v-row class="mt-3">
              <v-col cols="12" class="pt-0">
                <v-card-text style="font-size: 1.1rem;" class="text-wrap">
                    SMU SCIS takes pride in training computing professionals to create value for 
                    business and society, through innovative IT solutioning. 
                    The opportunity to attain this scholarship is a strong privilege 
                    we offer only to the most deserving applicants.                
                </v-card-text>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
    </v-row>

    <!-- row3 -->
    <v-row>
      <!-- card5 -->
      <v-col cols="12" md="12" lg="6">
        <v-card shaped class="experiencecard" href="https://scis.smu.edu.sg/programmes/undergraduate/students" target="__blank">
          <v-row>
            <v-col cols="12" xs="12" sm="7" md="9">
              <v-row>
                <v-col cols="12">
                  <v-card-title style="font-size: 1.38rem;" class="text-wrap">
                    SMU SCIS Dean's List (AY 21/22)
                  </v-card-title>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12">
                  <v-card-subtitle style="font-size: 1.1rem;" class="text-wrap">
                      Issued by SMU School of Computing & Information Systems · Aug 2022
                  </v-card-subtitle>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12" xs="12" sm="5" md="3" style="display: flex; align-items: center;">
              <v-img src="@/assets/smuscislogo.png" class="accomplishmentimage"></v-img>
            </v-col>
          </v-row>
          <v-row class="mt-3">
            <v-col cols="12" class="pt-0">
              <v-card-text style="font-size: 1.1rem;" class="text-wrap">
                  To qualify for a place on the Dean's List, a student must meet the following requirements:
                  <ul style="margin-top: 0.5rem;">
                      <li class="listaccomplishment">
                          Have a cumulative GPA of 3.70 / 4.00 or better over the 2 consecutive regular terms during 1 academic year (excluding Term 3)
                      </li>
                      <li class="listaccomplishment">
                          Have no grade lower than a "C"
                      </li>
                      <li class="listaccomplishment">
                          Have completed 3 or more course units for letter grades (grades that are computed into GPA) in each regular term
                      </li>
                      <li class="listaccomplishment">
                          Have no "I" grade and no disciplinary action taken against the student during the 2 consecutive regular terms                
                      </li>
                  </ul>
              </v-card-text>
            </v-col>
          </v-row>
        </v-card>
      </v-col>

      <!-- card6 -->  

  </v-row>
    </v-container>
</template>

<script>
export default {
  
}

</script>

<style>
.cardtitle{
  font-size: 1rem;
}
.listaccomplishment{
  margin-bottom: 0.5rem;
  font-size: 1.1rem;
}
.accomplishmentimage{
  height: 100%; 
  width: 100%; 
  margin-top: 0.5em; 
  padding-right: 2em;
}
@media only screen and (max-width: 960px) {
  .accomplishmentimage{
    height: auto; 
    width: 90% !important; 
    max-width: 100%; 
    margin-top: 0.5em; 
    padding-right: 2em;
  }
}
</style>